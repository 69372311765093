import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


   export const encrypt=(data:string)=>CryptoJS.AES.encrypt(data, 'viewlifeenergy').toString();
 
  export const decrypt=(data:string)=>{
  var byte =  CryptoJS.AES.decrypt(data, 'viewlifeenergy')
  return byte.toString(CryptoJS.enc.Utf8)
  } 

  