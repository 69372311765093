import React, {useEffect, useState} from 'react';
import {Alerts} from './components/notify'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Dashboard from './sites/dashboard'
import Plans from './sites/plans'


import Topup from './sites/topup'
import Payout from './sites/payout'
import Refferals from './sites/refferals'
import Clients from './sites/clients'
import RefferalEarn from './sites/refferalsearn'
import Investments from './sites/my-investments'
import ReInvestments from './sites/re-investments'
import Earnings from './sites/my-earnings'

import Online from './sites/online_payment'
import EarningsDetails from './sites/earnings'
import Authenticator from './sites/authenticator'
import Profile from './sites/profile'
import MessagesBulk from './sites/messages'

const ProtectedView =()=>{

    useEffect(()=>{
       setInterval(() => {
     var expiredTime = Number(localStorage.getItem('_vlifeinexptad'))
      if(expiredTime < Date.now()){
        Alerts('Info!', 'default', 'Your session has expired')
        window.location.href='/'
      }
    }, 5000)
    }, [])
    
        return ( <>
       
        <Router  >
          <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/clients" component={Clients} />


          <Route exact path="/online_payment" component={Online} /> 
            <Route exact path="/plans" component={Plans} />
            <Route exact path="/referrals" component={Refferals} />
            <Route exact path="/referrals/earnings" component={RefferalEarn} />
            <Route exact path="/topup" component={Topup} />
            <Route exact path="/payout" component={Payout} />
            <Route exact path="/investments" component={Investments} /> 
            <Route exact path="/earnings" component={Earnings} />
            <Route exact path="/earnings/:earnCode" component={EarningsDetails} />
            <Route exact path="/messages" component={MessagesBulk} />
            <Route exact path="/profile" component={Profile} />

        {/* <Route exact  component={Error404} /> 
          <Route exact path="/authenticator" component={Authenticator} />
        */}
      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;
