import React, {useState, useEffect, FormEvent,  useRef} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency, SiteLink} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {FormatNumber} from '../components/globalFunction'

const  Refferals = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [referal, setReferal] = useState([] as any[])
    const tableHeader = [
        {dataField: 'createAt', text: 'Date', editable: false},
        {dataField: 'amount', text: 'Amount Earn', editable: false, formatter:(cell:number)=>FormatNumber(Number(cell))+" "+Currency.title},
        {dataField: 'username', text: 'Username', editable: false},
        {dataField: 'fullname', text: 'Refer By', editable: false},
     ];

    
     const BasicTable=(props:{data:any, columns:any})=>{
             
            const { SearchBar } = Search;
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                               <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
 </div><br/>
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No referrals'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      const fetchContent =()=>{
          setNotice({...notice, isDataFetching: true}) 
          var sql ="Select r.ID, r.code, r.username, r.createAt, r.amount, u.username as fullname, r.referBy from tbl_refferal_list r, tbl_users u where u.username = r.referBy ";
  
          const fd = new FormData();
          fd.append("sql", sql);
          fd.append('jwt', Token);
          let url = ServerUrl+'/fetchBySql_controller.php'
          
          axios.post(url, fd, config).then(result=>{ 
              setReferal(result.data)
          })
          .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, isDataFetching: false}) 
          })
      }

    useEffect(()=>{
        fetchContent() 
         },[]);

    return (<Template title="Referrals">
       <div className="container pt-4">
           


            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="header-title text-center">Referrals List</h3>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                            <BasicTable data={referal} columns={tableHeader}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Refferals
