import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts, WarningModal } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Country from '../components/htmlCountry'
import {FormatNumber} from '../components/globalFunction'

const  Clients= ()=> {

    
    const [isEditable, setIsEditable] = useState(false)
const [password, setPassword] = useState('')
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [users, setUsers] = useState([] as any[])

    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:''
    });

    const [deposit, setDeposit] = useState({
       
        amount:'',
        balanceBefore:'',
        balanceAfter:'',
        transactionMethod:'',
        account:'Main Account'
   })
  
   const handleEditDeposit =(item:any)=>{
    setProfile(item);
    document.getElementById('openDialog')?.click()

    }


    const handleChangeTextArea = (event:ChangeEvent<HTMLTextAreaElement>) =>{		
        let {name, value} = event.target;	
        setDeposit({...deposit, [name] : value });
    }


    const handleChangeSelectDeposit = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setDeposit({...deposit, [name] : value });
    }

    const handleChangeDeposit = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;
        setDeposit({...deposit, [name] : value });
    }

    const [profile, setProfile] = useState({
            
        code:'',
        username:'',
        firstname:'',
        lastname:'',
        email:'',
        referal:'',
        telephone:'',
        country:'',
        password:'',
        twoFA:'',
        isTwoFA:'',
        isEmailVerified:'',
        status:'',
        balance:''

   })


const changePassword =(event:ChangeEvent<HTMLInputElement>)=>{
	let {name, value} = event.target;
	setPassword(value);
}


function updatePassword(){  
    if(password===''){
        Alerts('Error!', 'danger', 'Please enter  new password')
    }else{
      setNotice({...notice,  isLoading: true})     
      
      const fd = new FormData();
      fd.append('password', password)
      fd.append('code', profile.code)
      fd.append('jwt', Token);
      let url = ServerUrl+'/update_controller.php?tablename=tbl_update_user_password';
        axios.post(url, fd, config)
        .then(response =>{
         if(response.data.type ==='info'){	
            Alerts('Saved!', 'info', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            
            setNotice({...notice,  isLoading: false })
        })   
}
}


    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setProfile({...profile, [name] : value });
    }

    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;
        setProfile({...profile, [name] : value });
    }

         const handleEditProfile =(item:any)=>{
            setProfile(item);
            setIsEditable(true)
            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
            }
    
    
        const handleDelete = (code:string)=>{  
            setNotice({...notice,  isLoading: true}) 
               let close =   document.getElementById('btnWarningDialog-'+code)
                  close?.click();
                  const fd = new FormData();
                  fd.append('jwt', Token); 
               fd.append('ID', 'code')
                fd.append('data', code)
        
                let url = ServerUrl+'/delete_controller.php?tablename=tbl_users'
               axios.post(url, fd, config)
               .then(response =>{
                if(response.data.type ==='success'){
                   Alerts('Saved!', 'success', response.data.message)
                       } else{
                   Alerts('Error!', 'danger', JSON.stringify(response.data))
                       }   
               })
               .catch((error)=>{
                 Alerts('Error!', 'danger', error.message)
               }).finally(()=>{
                fetchContent()
                   setNotice({...notice, isLoading: false
                   })
               }) 
        }
    
    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 

        var sql ="Select code, ID, username, firstname, lastname, email, telephone, country, balance, referal, date_create from tbl_users order by ID DESC";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setUsers(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }

   

    const CreditAccount =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];
       
     if (errormessage.length <=0) {
       setNotice({...notice, isLoading: true})     
        const fd = new FormData();

        fd.append('code', 'd'+Math.random().toString(36).slice(2,9));
        fd.append('userCode', profile.code);
        fd.append('amount', deposit.amount);
        fd.append('account', deposit.account);
        fd.append('status', 'Completed');
        fd.append('balanceBefore', profile.balance);
        fd.append('email', profile.email);
        fd.append('balanceAfter', (Number(profile.balance)+ Number(deposit.amount)).toString());
        fd.append('jwt', Token);
        fd.append('transactionMethod', deposit.transactionMethod);
        fd.append('email', profile.email);
        fd.append('fullname', profile.firstname + ' '+profile.lastname);

        let url = ServerUrl+'/save_controller.php?tablename=tbl_credit_account';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
         setErrors({...errors, successMessage: 'Your transaction was successfully.'})
               
                  } else{
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                document.getElementById('closeModal')?.click()
                setNotice({...notice,  isLoading: false })
 fetchContent()
              })   
        } 

      }




	const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();

    setNotice({...notice, isLoading: true})     
    const fd = new FormData();
    Object.entries(profile).forEach(([key, value]) => {
        fd.append(key, value);
    }); 
    fd.append('jwt', Token);
    
   let url = ServerUrl+'/update_controller.php?tablename=tbl_users';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.type === 'info'){
        Alerts('Success!', 'info', response.data.message)
           } else{
            //Alerts('Error!', 'danger', response.data)
             
            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
     setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
              }   
          })
          .catch((error)=>{
             
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              
fetchContent()

setIsEditable(false)
            setNotice({...notice,  isLoading: false })
          })   

      }



    const tableHeader = [

        {dataField: 'code', text: 'Action', formatter: (cell:string, row:any)=><div>

        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 


     
            <div className="dropdown">
          <button className="btn btn-sm btn-outline-success dropdown-toggle ripple-surface" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false" style={{minWidth: '178px'}}>
            Action
          </button>


          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-start" data-mdb-popper="null" style={{ position: 'absolute', transform: 'translate3d(319px, 38px, 0px)',inset: '0px auto auto 0px', margin: '0px'}} >

            <li><a className="dropdown-item" href="#!" onClick={()=>handleEditDeposit(row)}> <i className='material-icons'>card_giftcard</i> Credit Account</a></li>


            <li><a className="dropdown-item" href="#!" onClick={()=>handleEditProfile(row)}> <i className="material-icons">edit</i> Edit Profile</a></li>
            <li><a className="dropdown-item" href="#!"><i className='material-icons'>key</i> Login as this Client</a></li>

            <li><a className="dropdown-item" href="#!" data-mdb-toggle="modal"   data-mdb-target={`#warningdialog-${row.code}`} title="Delete"> <i className="material-icons">delete</i> Delete Account </a></li>

          </ul>
        </div>

                  </div>, editable: false}, 
                  
        {dataField: 'country', text: 'Country', editable: false}, 
        {dataField: 'username', text: 'Username', editable: false},
        {dataField: 'firstname', text: 'Fullname', editable: false, formatter:(cell:number, row:any)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {row.firstname + " "+row.lastname}</span> },
        {dataField: 'email', text: 'Email', editable: false},
        {dataField: 'telephone', text: 'Telephone', editable: false},

        {dataField: 'balance', text: 'Main Balance', editable: false, formatter: (cell:Number)=>FormatNumber(cell)+' '+Currency.title},

        {dataField: 'referal', text: 'Referal Balance', editable: false, formatter: (cell:Number)=>FormatNumber(cell)+' '+Currency.title},
        {dataField: 'date_create', text: 'Created On', editable: false}
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
             
    const { SearchBar } = Search;
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                               <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
 </div><br/>


                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No Transaction Found'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      useEffect(()=>{
        fetchContent() 
         },[]);



    return (<Template title="Clients">
       <div className="container pt-4">



                   {errors.successMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-success" role="alert" data-mdb-color="success">
          <strong>Success!</strong> {errors.successMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


                   {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


                  <a href="#!" data-mdb-toggle="modal" id="openDialog"  data-mdb-target={`#warningdialog-1`}> </a>
                   <div className="modal fade " id={`warningdialog-1`} tabIndex={-1} role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
              <div className="modal-body">
                <h3 className="text-dark text-bold">Credit Account ({profile.username})</h3>
                
              <form id="action" method="post" action="#!" onSubmit={CreditAccount}>
            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">{Currency.unicode}</span>
                    </div>
                    <input type="number" value={deposit.amount}  onChange={handleChangeDeposit} className="form-control" id="amount" placeholder='Amount..' name="amount" pattern="\d*" required  />
                    <div className="input-group-append">
                        <span className="input-group-text">{Currency.title}</span>
                    </div>
                </div>
            </div>
                                <div className="form-group">
                                    <label htmlFor="method">Account to Credit</label>
                             
                         <select name="account" id="account" value={deposit.account}   onChange={handleChangeSelectDeposit} className="form-control" required>
                <option value="">Select</option>                                                                      <option value="Main Account">Main Wallet</option>                    
                            <option value="Referral Earning">Referral Earning</option>
                                            </select>
                                </div>
                               

                                <div className="form-group bmd-form-group">
                    <label htmlFor="info" className="bmd-label-static">Description</label>
                    <textarea name="transactionMethod" required className="form-control" value={deposit.transactionMethod}  onChange={handleChangeTextArea}  placeholder="Information related to the deposit">{deposit.transactionMethod}</textarea>
                </div>


              <div className="modal-footer">
                  <button type="button"  id={`closeModal`} className="btn btn-inverse waves-effect " data-mdb-dismiss="modal">Close</button>


                  {notice.isLoading?
          
          <button disabled className="btn btn-outline-primary" type="button">
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>: <div> <button type="submit" className="btn btn-success">Credit Account</button>
                              </div>}
                 
              </div>

              </form>
              </div>
          </div>
      </div>
  </div>


           
                   {isEditable?<div>

<div className="row justify-content-center">
        <div className="col-lg-12">
            <h2 className="text-center">Update Profile</h2>
        </div>
    </div>

    <div className="row">

    <div className="col-lg-12">

<div className="card">
    <div className="card-header"><h5 className="header-title">User &amp; Email Settings</h5></div>
    <div className="card-body">
  <form  method="post" onSubmit={submit}>


            <div className="form-group bmd-form-group is-filled mb-3">
                <label htmlFor="username" className="bmd-label-static">Username</label>

<input type="text"  name="username" value={profile.username}  onChange={handleChange} className='form-control pl-2' required /> 

            </div>

            <div className="form-group bmd-form-group is-filled mb-3">
                <label htmlFor="first_name" className="bmd-label-static">First Name</label>

<input type="text"  name="firstname" required value={profile.firstname} placeholder="First Name..."  onChange={handleChange} className='form-control' /> 

            </div>

            <div className="form-group bmd-form-group is-filled mb-3">
                <label htmlFor="last_name" className="bmd-label-static">Last Name</label>

<input type="text"  name="lastname"  value={profile.lastname} placeholder="Last Name..." onChange={handleChange} className='form-control' required /> 

            </div>

            <div className="form-group bmd-form-group is-filled mb-3">
                <label htmlFor="email" className="bmd-label-static">Email</label>
                <input type="email" required name="email" value={profile.email}  onChange={handleChange} className='form-control'   />

            </div>

            <div className="form-group bmd-form-group is-filled mb-3">
                <label htmlFor="telephone" className="bmd-label-static">Telephone</label>

<input type="text"  name="telephone" pattern="\d*" title="Must contain digit only"  value={profile.telephone} placeholder="Phone..."  onChange={handleChange} className='form-control' required />

                
            </div>

            <div className="form-group mb-3">
                <label htmlFor="country">Country</label>
        <Country country={profile.country} handleSelect={handleChangeSelect} />
              
            </div>


            {notice.isLoading?

<button disabled className="btn btn-primary" type="button">


<img src={ImagesUrl+"/loading.gif"} /> 
Please wait...</button>:
            <button type="submit"  className="btn btn-primary">Update</button>}
<button type="button" onClick={()=>setIsEditable(false)}  className="btn btn-default">Cancel</button>


            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Change Password</h2>
                </div>
            </div>
            <div className="form-group bmd-form-group is-filled mb-3">
                <label htmlFor="password" className="bmd-label-static">Password</label>

                <div className="input-group">
                <input type="password" name="password"  value={password} placeholder="Enter the new password..."  onChange={changePassword} className='form-control' /> 

                <div className="input-group-append">
                                            <button type="button" onClick={updatePassword} className="btn btn-info input-group-text"><span className="material-icons"> key</span> Change Password</button>
                                        </div>

                </div>
            </div>



        </form>

    </div>
</div>
</div>

</div>
</div>:''}




            <br />

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header text-center">All Users</div>
                        <div className="card-body">
                            <div className="table-responsive">
                                
                                <BasicTable data={users} columns={tableHeader}  />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Clients
