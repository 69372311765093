import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {FormatNumber} from '../components/globalFunction'

const  Deposit = ()=> {

    
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [transaction, setTransaction] = useState([] as any[])

    const [topup, setTopup] = useState({
        amount:'',
        method:'Crypto'
    })


    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
        setTopup({...topup, [name] : value });
    }

    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setTopup({...topup, [name] : value });
    }
    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 

        var sql ="Select d.code, d.userCode, u.firstname, u.lastname,  d.amount, d.status, d.dateCreate, d.balanceBefore, d.balanceAfter, d.transactionMethod from tbl_deposit d, tbl_users u where d.userCode =u.code and d.coinbaseId = '' and d.chargeId ='' order by d.ID DESC";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setTransaction(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }

    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:''
    });


    const tableHeader = [
        {dataField: 'firstname', text: 'Username', editable: false, formatter:(cell:number, row:any)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {row.firstname + " "+row.lastname}</span> },
        {dataField: 'dateCreate', text: 'Date Create', editable: false},
        {dataField: 'transactionMethod', text: 'Method', editable: false},

        {dataField: 'amount', text: 'Amount', editable: false, formatter: (cell:Number)=>FormatNumber(Number(cell))+' '+Currency.title},

        {dataField: 'status', text: 'Status', editable: false},


        {dataField: 'balanceBefore', text: 'Balance Before', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(Number(cell)) + " "+Currency.title}</span>},

        {dataField: 'balanceAfter', text: 'Balance After', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(Number(cell)) + " "+Currency.title}</span>},
   
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
             
    const { SearchBar } = Search;
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                               <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
 </div><br/>
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No Transaction Found'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

        if(Number(topup.amount) < 100 || Number(topup.amount) > 10000  ){
            errormessage.push('error');
        setErrors({...errors, errorMessage: 'Deposit amount must be between 100.00 and 10000.00.'})

        }
          

        if (topup.method === 'Perfect Money'){
            setErrors({...errors, errorMessage: 'This option is currently unavailble'})
            errormessage.push('error');
            }




     if (errormessage.length <=0) {
       setNotice({...notice, isLoading: true})     
        const fd = new FormData();
        
        fd.append('jwt', Token);
        fd.append('amount', Token);
        fd.append('jwt', Token);

        /* 
        let url = ServerUrl+'/save_controller.php?tablename=tbl_payout';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
         setErrors({...errors, successMessage: 'Your payout request was successfully received!.'})
               
                  } else{
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                document.getElementById('closeModal')?.click()
                setNotice({...notice,  isLoading: false })
              
              })    */
        } 
    }


    useEffect(()=>{
        fetchContent() 
         },[]);


    return (<Template title="Top up">
       <div className="container pt-4">

{errors.errorMessage?
   
   <div className="alert alert-dismissible alert-warning">
                       <button type="button" className="close" data-dismiss="alert">×</button>
                       <strong>Error!</strong> {errors.errorMessage}
                   </div>:''} 


         


            <br />

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <h3 className="card-header text-center"> Deposit Made To User Account</h3>
                        <div className="card-body">
                            <div className="table-responsive">
                                <small><p className="text-warning">Perfect Money deposits are visible only after successful deposits</p></small>
                                
                                
                                <BasicTable data={transaction} columns={tableHeader}  />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Deposit
