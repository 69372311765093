import { Sitename} from '../components/include'
const Footer =()=> {
    return (
        <footer className="bg-light text-lg-start">
       
    
    <hr className="m-0" />


    <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
    © {new Date().getFullYear()} Copyright:
       <a className="text-dark" href="#!"> {Sitename}</a>
    </div>
  </footer>

    )
}

export default Footer