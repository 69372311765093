import {useState, useEffect, FormEvent, ChangeEvent} from 'react'
import {ServerUrl,  config,  ImagesUrl} from '../components/include'
import axios from 'axios'
import Footer from './footer'
import Cookies from 'js-cookie'
import {encrypt} from '../components/authentication'

const  Login = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [user, setUser] = useState({
		email:'',
		password:'',
        twoFA:'',
		remember:'false',
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({
        errorMessage:''
    });

    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{	
        let {name, value} = event.target;		
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }

                
     const submit =(event: FormEvent<HTMLFormElement>)=>{ 
        event.preventDefault();
		setNotice({...notice, isLoading: true}) 	
           

        const fd = new FormData();
        Object.entries(user).forEach(([key, value]) => {
            fd.append(key, value);
        }); 

           let url = ServerUrl+'/login_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes = Date.now() + 59 * 60 * 2000;
			var inOneMinutes = Date.now() + 58 * 60 * 2000;
			  var inOneHours = new Date(new Date().getTime() + 59 * 60 * 2000);
			  
			  localStorage.setItem('_vlifeinexptad', inSixtyMinutes.toString())
				localStorage.setItem('_vlifeinbexptad', inOneMinutes.toString())
			Cookies.set('vlifeinvcdad', encrypt(response.data.code), {expires: inOneHours })
			Cookies.set('vlifeinvtkad', encrypt(response.data.jwt),  {expires: inOneHours }) 
			//Alerts('Welcome!', 'success', response.data.message)
      
			var queryString = new URLSearchParams(window.location.search).get("refferer")
            if(queryString!==null){
                window.open(queryString, '_self')
            }else{
                window.open('/dashboard', '_self')
            }
			
			}else if (response.data.type ==='error'){
				setErrors({...errors, errorMessage: response.data.message})
			}    
		})
		.catch((error)=>{
            setErrors({...errors, errorMessage: JSON.stringify(error.message, null, 2)})
				 
		}).finally(()=>{
            setNotice({...notice,  isLoading: false }) 
        })     
}



const clearCookies=()=>{
	Cookies.remove('vlifeinvcdad')
	 Cookies.remove('vlifeinvtkad')
  localStorage.setItem('_vlifeinexptad', Date.now().toString())
  localStorage.setItem('_vlifeinbexptad', Date.now().toString())
}

useEffect(()=>{  
  clearCookies()
},[]);



    return (
        
        <> 
    <header>
       
        <div id="intro" className="bg-image shadow-2-strong">
          <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
            <div className="container">
               


            {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


              <div className="row justify-content-center ">
                <div className="col-xl-5 col-md-8">


                <form className="form" method="POST" onSubmit={submit} role="presentation">
                    <div className="card card-login card-hidden mb-3" style={{ background: 'linear-gradient(#1225f1, #0a1370)'}}>
                        <div className="card-header card-header-warning text-center">
                            <h4 className="card-title"><strong style={{color:'yellow'}}>Login</strong></h4>
                        </div>
                        <div className="card-body">
                          
                            <div className="bmd-form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="material-icons">email</i>
                                      </span>
                                    </div>
                                    <input type="email" required name="email" role={"presentation"} autoComplete="off"  value={user.email}  onChange={handleChange} className='form-control pl-2 '  placeholder=" Email..."  />
                                </div>
                                                   
                                                            </div>


                            <div className="bmd-form-group mt-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="material-icons">lock_outline</i>
                                      </span>
                                    </div>

                                    <input type="password" name="password" role={"presentation"} autoComplete="off"   value={user.password} placeholder="Password..." required  onChange={handleChange} className='form-control pl-2 ' /> 

                                </div>
                                                           
                                                            </div>
                            <div className="form-check mr-auto ml-3 mt-3">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox"
                                           name="remember" /> Remember me
                                    <span className="form-check-sign">
                                      <span className="check"></span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="d-block justify-content-center mx-3 mb-4">
                       {notice.isLoading?
          
          <button disabled className="btn btn-warning btn-link btn-lg" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:     
              <button type="submit" className="btn btn-warning btn-link btn-lg">Login</button>}

                            <div className="row">
                                <div className="col-6">
                                    <a href="/forgot_password" className="text-warning">
                                        <small>Forgot password?</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer />

      
      
      </>
    )
}

export default Login
