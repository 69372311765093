import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts, WarningModal, InfoModal } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {FormatNumber} from '../components/globalFunction'
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import {Type} from 'react-bootstrap-table2-editor';

const  Investment = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [invest, setInvest] = useState([] as any[])

    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:''
    });

    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 

        var sql ="Select i.code, u.firstname, u.lastname, u.balance, u.code as usercode, p.code as planCode, i.ID, p.planName, p.duration, i.investment, i.isPayout, i.currentReturn, i.toReturn, i.returnPerDay, i.startDate, i.endDate, i.status, i.userCode from tbl_users u, tbl_user_investment i, tbl_investment_plan p  where  i.planId = p.code and i.userCode = u.code order by i.ID DESC ";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setInvest(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }


    const handleDelete = (code:string)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close?.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller.php?tablename=tbl_user_investment'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
             fetchContent()
               setNotice({...notice, isLoading: false
               })
           }) 
    }


    const getCurrentReturn=(row:any)=>{
        
        if(row.isPayout === 'No'){
        var fromDate =  new Date(row.startDate);
        var toDate =  new Date();
        var diff_time = toDate.getTime()-fromDate.getTime()

        var differ = diff_time/(1000*3600*24);
        var total =0;
        if(Number(row.duration)>=Number(differ)){
             total = Number(differ) * Number(row.returnPerDay);
        }else{
            total = Number(row.duration) * Number(row.returnPerDay);
        }
       
        return total.toFixed(2)
    }else{

        return row.currentReturn
        }
    }
var i =1;
    const tableHeader = [
        {dataField: 'code', text: 'SN', editable: false, formatter: ()=>i++},

        {dataField: 'firstname', text: 'Username', editable: false, formatter:(cell:number, row:any)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {row.firstname + " "+row.lastname}</span> },
        {dataField: 'planName', text: 'Plan', editable: false},
        {dataField: 'investment', text: 'Investment', editable: false, formatter: (cell:Number)=>FormatNumber(cell)},

        {dataField: 'currentReturn', text: 'Current Return', editable: false, formatter:(i:string, row:any)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> { getCurrentReturn(row)+ ' '+Currency.title}</span>},

        {dataField: 'toReturn', text: 'Total to be returned', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {cell + " "+Currency.title+" + Capital"}</span>},

        {dataField: 'returnPerDay', text: 'Return per day', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {cell + " "+Currency.title}</span>},

        {dataField: 'startDate', text: 'Start on', editable: false},

        {dataField: 'endDate', text: 'Ends on', editable: false},

        {dataField: 'status', text: 'Status',  formatter:(cell:string)=><span 
    
        className={` badge 
                        ${cell==='Active'?'bg-success':
                        cell==='Expired'?'bg-danger':
                        cell==='Pending'?'bg-warning':
                        cell==='Completed'?'bg-secondary':
                        'btn-default'}`}> {cell}</span>},
            
            
                        
/* 
            {dataField: 'code', text: 'Action', editable: false, formatter:(cell:string, row:any)=><div>


        {row.isPayout==='No'?    <button type="button" name="edit" className="btn btn-primary btn-sm"  data-mdb-toggle="modal"  data-mdb-target={`#infoDialog-${row.code}`} >  <i className="fa fa-money-bill"></i> Payout</button>:''}
            
            &nbsp;
            
            <InfoModal title={'You are about to credit the account of '+row.firstname+' '+row.lastname+ ' With total amount of '+(Number(getCurrentReturn(row))+Number(row.investment)).toFixed(2)+' '+Currency.title+'. Are you really sure about this ? '} handleConfirm={()=>CreditAccount(row)} mID={row.code} /> 

             <button type="button" name="delete" data-mdb-toggle="modal"  data-mdb-target={`#warningdialog-${row.code}`}   className="btn btn-danger btn-sm" >  <i className="fa fa-trash"></i> Delete</button> 
                            
                      <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 
            
                        </div>} */
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
             
        const handleUpdate=(column:any, newValue:any, ID:any)=>{       
            const fd = new FormData(); 
            fd.append("jwt", Token);
                fd.append('columnField', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
               let url = ServerUrl+'/updateById_controller.php?tablename=tbl_user_investment';
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>console.log(error)) 
              
            } 

            const { SearchBar } = Search;

              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                               <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
 </div><br/>
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No Investment Found'}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue:any, newValue:any, row:any, column:any) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.ID);
                                
                               return '';
                              }
                              
                            }
                                      }) }

                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      const CreditAccount =(row:any)=>{
        document.getElementById('btnInfoDialog-'+row.code)?.click()
        setNotice({...notice, isLoading: true}) 
        const fd = new FormData();
      
var earn = Number(row.investment)+Number(getCurrentReturn(row))
        fd.append('code', 'e'+Math.random().toString(36).slice(2,9));
        fd.append('contractCode', row.code);
        fd.append('planCode', row.planCode);
        fd.append('amountEarn', earn.toFixed(2));
        fd.append('balanceBefore', row.balance)
        fd.append('balanceAfter', (Number(row.balance)+ Number(earn)).toFixed(2));
        fd.append('userCode', row.usercode);

        fd.append('jwt', Token);
        
        let url = ServerUrl+'/save_controller.php?tablename=tbl_cashout_user';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
         setErrors({...errors, successMessage: 'Your transaction was successfully.'})
               
                  } else{
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                setNotice({...notice,  isLoading: false })
                fetchContent()
              })   
        

      }




    useEffect(()=>{
        fetchContent() 
         },[]);


    return (<Template title="Client Investments">
        <div className="container pt-4">

        <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Client Investments</h2>
                </div>
            </div>


            {errors.successMessage?
   
   <div className="alert alert-dismissible alert-success">
                       <button type="button" className="close" data-dismiss="alert">×</button>
                       <strong>Success!</strong> {errors.successMessage}
                   </div>:''} 


{errors.errorMessage?
   
   <div className="alert alert-dismissible alert-warning">
                       <button type="button" className="close" data-dismiss="alert">×</button>
                       <strong>Error!</strong> {errors.errorMessage}
                   </div>:''} 

            <div className="row">
                <div className="col-lg-12">
                    <div className="card py-4">
                        <div className="card-body">
                            <div className="table-responsive">
                         <BasicTable data={invest} columns={tableHeader}  />
                            </div>



           

                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Investment
