import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


export  const ServerUrl = 'https://backendinvestors.premium2homes.com/controller';
export  const ImagesUrl = 'https://backendinvestors.premium2homes.com/images';
export  const SiteLink = 'https://investors.premium2homes.com'; 

export const Sitename = 'Premium2homes Ltd';

/* export  const ServerUrl = 'http://192.168.64.3/React/kennedy/viewlifeenergy/controller';
export  const ImagesUrl = 'http://192.168.64.3/React/kennedy/viewlifeenergy/images';
export  const SiteLink = 'http://localhost:3000'; */   


export const Currency = {
    unicode: '₦',
    title: 'NGN'
}


export const Code = Cookies.get('vlifeinvcdad')?CryptoJS.AES.decrypt(Cookies.get('vlifeinvcdad'), 'viewlifeenergy').toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('vlifeinvtkad')?CryptoJS.AES.decrypt(Cookies.get('vlifeinvtkad'), 'viewlifeenergy').toString(CryptoJS.enc.Utf8):'null'
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 