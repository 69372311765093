import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import Template from '../components/template'
import {ServerUrl,  config, ImagesUrl,  Token, Code} from '../components/include'

const  Profile = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isLoadingPass:false,
        isDataFetching: false
	});
    const [users, setUsers] = useState([] as any[])
    const [errors, setErrors] = useState({
        successMessage:'',
        errorMessage:'',
        confirmPassword:''
    });

    const [pass, setPass] = useState({
        newPassword:'',
        confirmNewPassword:'',
        oldPassword:'',
        twoFA:''
    })

    const handleChangePassword = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
       setPass({...pass, [name] : value });
       setErrors({...errors, [name]:''});
    }


    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		

        let {name, value} = event.target;
        const prof =JSON.parse(JSON.stringify(users))        
		   prof[0][name] = value;
        setUsers(prof);
       setErrors({...errors, [name]:''});
    }


    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 
        var sql ="Select code, password, username, email from tbl_admin where code ='"+Code+"'";
         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 

            setUsers(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
        
    }
    const submitPassword =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

      if(pass.newPassword !== pass.confirmNewPassword){
          setErrors({...errors, confirmPassword:'Password does not match.'})
          errormessage.push('Password does not match.');
      }

        if (errormessage.length<=0) {
            updatePassword()
        }
    }


function updatePassword(){  
    setNotice({...notice,  isLoadingPass: true}) 	
       
    const fd = new FormData();
    Object.entries(pass).forEach(([key, value]) => {
        fd.append(key, value);
    }); 

    fd.append('jwt', Token);
    fd.append('code', Code)
       let url = ServerUrl+'/update_controller.php?tablename=tbl_update_password';
    axios.post(url, fd, config)
    .then(response =>{

    if (response.data.type ==='success'){
        Alerts('Success!', 'success', response.data.message) 
        }else if (response.data.type ==='error'){
         
            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
     setErrors({...errors, errorMessage: response.data.message})    
        }    
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
         
    }).finally(()=>{
        setNotice({...notice,  isLoadingPass: false }) 
        
    setPass({
        newPassword:'',
        confirmNewPassword:'',
        oldPassword:'',
        twoFA:''
    })
}) 
}



	const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();

    setNotice({...notice, isLoading: true})     
    const fd = new FormData();

    let formData = users[0];
    for(let k in formData){
        fd.append(k,formData[k]);
    }; 

    fd.append('jwt', Token);
    
   let url = ServerUrl+'/update_controller.php?tablename=tbl_admin';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.type === 'info'){
        Alerts('Success!', 'info', response.data.message)
           } else{
            //Alerts('Error!', 'danger', response.data)
             
            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
     setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
              }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setNotice({...notice,  isLoading: false })
          })   

      }


    useEffect(()=>{
         fetchContent() 
          },[]);

    return (<Template title="Profile">

{notice.isDataFetching? <div className="loader"> <img src={ImagesUrl+"/loading.gif"} /> 
          </div>:
      
      <div className="container pt-4">

{errors.errorMessage?
   <div className="alert alert-dismissible alert-danger">
                       <button type="button" className="close" data-dismiss="alert">×</button>
                       <strong>Error!</strong> {errors.errorMessage}
                   </div>:''}

            <div className="row">

                
                <div className="col-lg-12">

                    <div className="card">
                        <div className="card-header"><h5 className="header-title">User &amp; Email Settings</h5></div>
                        <div className="card-body">

                       {users.length>0? users.map((user, id)=>   <form key={id} method="post" onSubmit={submit}>
                                <div className="form-group bmd-form-group is-filled mb-3">
                                    <label htmlFor="username" className="bmd-label-static">Username</label>

                 <input type="text"  name="username"    value={user.username}  onChange={handleChange} className='form-control pl-2' required /> 

                                </div>


                                <div className="form-group bmd-form-group is-filled mb-3">
                                    <label htmlFor="email" className="bmd-label-static">Email</label>
                                    <input type="email" required name="email"  value={user.email}  onChange={handleChange} className='form-control'   />

                                </div>

                             
                                {notice.isLoading?
          
          <button disabled className="btn btn-primary mb-3" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:
                                <button type="submit"  className="btn btn-primary mb-3">Update</button>}
                            </form>):'' }

                        </div>
                    </div>



                    <br />
                    <div className="card ">
                        <div className="card-header"><h5 className="header-title">Password Modification</h5></div>
                        <div className="card-body">

                            <form method="post" action="#"  onSubmit={submitPassword}>
                                <div className="form-group bmd-form-group mb-3">
                                    <label htmlFor="current_password" className="bmd-label-static">Current Password</label>

                                    <input type="password" name="oldPassword"  value={pass.oldPassword} placeholder="Enter current password..." required  onChange={handleChangePassword} className='form-control' /> 

                                </div>
                                <div className="form-group bmd-form-group mb-3">
                                    <label htmlFor="new_passowrd" className="bmd-label-static">New Password</label>
                                    

                                    <input type="password" name="newPassword"  value={pass.newPassword} placeholder="Enter new password..." required  onChange={handleChangePassword} className='form-control' /> 

                                </div>
                                <div className="form-group bmd-form-group mb-3">
                                    <label htmlFor="confirm_passowrd" className="bmd-label-static">Confirm Password</label>
                                   
                                    <input type="password" name="confirmNewPassword"  value={pass.confirmNewPassword} placeholder="Enter again the new password..." required  onChange={handleChangePassword} className='form-control' /> 

                                    <span className=" form-bar error">{ errors.confirmPassword}</span>
                                </div>


{notice.isLoadingPass?
          
          <button disabled className="btn btn-primary" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:
                                <button type="submit" className="btn btn-primary">Update</button>}
                            </form>

                        </div>
                    </div>
                </div>

            </div>

        </div>}
</Template>
      
    )
}

export default Profile
