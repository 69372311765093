import React, {useState, useEffect, FormEvent,  useRef} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency, SiteLink} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { useSelector } from 'react-redux'
import {FormatNumber} from '../components/globalFunction'

const  Refferals = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [totalEarn, setTotalEarn] = useState(0)
    const [referal, setReferal] = useState([] as any[])
    const user  = useSelector((state:any) => state.rootReducer.user);
    const textAreaRef = useRef(null);
    const tableHeader = [
        {dataField: 'createAt', text: 'Date', editable: false},
        {dataField: 'username', text: 'User', editable: false},
        {dataField: 'description', text: 'Description', editable: false},
        {dataField: 'amount', text: 'Amount', editable: false},
        
     ];

     const copyToClipboard =()=>{
         
         const el = document.createElement('textarea');
         el.value = SiteLink+"/register?ref="+Code;
         document.body.appendChild(el);
         el.select();
         document.execCommand('copy');
         document.body.removeChild(el);
         Alerts('Info!', 'info', 'Referral link copied to clipboard')
     }
  
     const BasicTable=(props:{data:any, columns:any})=>{
             
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No earnings'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      const fetchContent =()=>{
          setNotice({...notice, isDataFetching: true}) 
          var sql ="Select ID, code, username, description, createAt, amount from tbl_refferal_list where referBy ='"+Code+"' ";
  
          const fd = new FormData();
          fd.append("sql", sql);
          fd.append('jwt', Token);
          let url = ServerUrl+'/fetchBySql_controller.php'
          
          axios.post(url, fd, config).then(result=>{ 
              setReferal(result.data)
              var total = 0

              if(Array.isArray(result.data)&&  result.data.length>0){
                result.data.map(item=>{
                    total = total + Number(item.amount)
                })

                setTotalEarn(total)
              }
          })
          .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, isDataFetching: false}) 
          })
      }

    useEffect(()=>{
        fetchContent() 
         },[]);

    return (<Template title="Referrals">
       <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Referrals</h2>
                </div>
            </div>

            <div className="row">

    <div className="col-lg-6">
        <div className="card refCard text-center">
            <div className="card-body">
                <h4 className="refEarningsText">Total Referral Earnings</h4>

                <h5>  {FormatNumber(totalEarn)}    </h5>

                <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Current Referral Balance
                        <span className="badge badge-secondary badge-pill">{FormatNumber(Number(user.referal))} {Currency.title}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        All Time Earnings
                        <span className="badge badge-secondary badge-pill"> {FormatNumber(totalEarn)} {Currency.title}</span>
                    </li>
                </ul>
                <a href="/payout">Withdraw here</a>
            </div>
        </div>
    </div>
    <br />
    <div className="col-lg-6">
        <div className="card">
            <div className="card-header">
                <h3 className="header-title">Overview</h3>
            </div>
            <div className="card-body">
                <div className="form-group bmd-form-group is-filled">
                    <label htmlFor="ref" className="bmd-label-static">Your Referral Link</label>
                    <input type="text" id="textAreaRef"  ref={textAreaRef} readOnly={true} className="form-control bg-white" value={SiteLink+"/register?ref="+Code} />
                </div>
                <button className="btn btn-primary btn-block" onClick={copyToClipboard} >Copy Link</button>
                <h4 className="text-center">Referrals Count.</h4>
                <h5 className="text-center">
                    {referal.length}  <a href="/referrals">View Referrals</a>
                                    </h5>
            </div>
        </div>
    </div>

</div>


            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="header-title">Earning Records</h3>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                            <BasicTable data={referal} columns={tableHeader}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Refferals
