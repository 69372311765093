import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts, WarningModal } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl, Currency,  Token, Code} from '../components/include'
import Template from '../components/template'

import { FormatNumber } from '../components/globalFunction';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


const  Plans = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [plan, setPlan] = useState([] as any[])
    const [isEditable, setIsEditable] = useState(false)
    
    const [errors, setErrors] = useState({
        errorMessage:''
    });

    const [investment, setInvestmentPlan] = useState({
         code:'', 
        planName:'',
         duration:'', 
         minPlan:'', 
         amount:'',
          maxPlan:'', 
          status:'',
           roiRate:''
    })


    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setInvestmentPlan({...investment, [name] : value });
    }

    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;
        setInvestmentPlan({...investment, [name] : value });
    }


    interface itemlist{
        code:string, 
        planName:string,
         duration:number, 
         minPlan:number, 
         amount:number,
          maxPlan:number, 
          status:string,
           roiRate:number
    }
    

    const handleEditPlan =(item:any)=>{
        setInvestmentPlan(item);
        setIsEditable(true)
        window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
        }


    const handleDelete = (code:string)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close?.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller.php?tablename=tbl_investment_plan'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
             fetchPlan()
               setNotice({...notice, isLoading: false
               })
           }) 
    }




    const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

      if(Number(investment.minPlan) < 0 ||  Number(investment.maxPlan)<0){
        Alerts('Error!', 'danger', 'Please enter digit only on Min and Max Plan')
          errormessage.push('error');
      }

     if (errormessage.length<=0) {
       
        setNotice({...notice, isLoading: true})     
        const fd = new FormData();
        Object.entries(investment).forEach(([key, value]) => {
            fd.append(key, value);
        }); 
        fd.append('jwt', Token);
        let url = ServerUrl+'/update_controller.php?tablename=tbl_investment_plan';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'info'){
            Alerts('Success!', 'info', 'You have successfully updated an investment!')
          
                  } else{
                
            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                fetchPlan() 
                setIsEditable(false)
                setNotice({...notice,  isLoading: false })
              }) 

        } 
    }
    
var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'SN', editable: false, formatter: (cell:Number)=>i++},
        {dataField: 'planName', text: 'Plan Name', editable: false},
        {dataField: 'duration', text: 'Duration', editable: false, formatter:(cell:string)=>cell+" Days"},
        {dataField: 'minPlan', text: 'Min', editable: false, formatter: (cell:Number)=>Number(cell)!==0?FormatNumber(cell)+" "+Currency.title:'Unlimited'},
        {dataField: 'maxPlan', text: 'Max', editable: false, formatter: (cell:Number)=>Number(cell)!==0?FormatNumber(cell)+" "+Currency.title: 'Unlimited'},
        {dataField: 'roiRate', text: 'ROI Rate', editable: false, formatter:(cell:string)=>cell+"%"},
        {dataField: 'status', text: 'Status', editable: false, formatter:(cell:string)=><span 
    
        className={` badge 
                        ${cell==='Active'?'bg-success':
                        cell==='Canceled'?'bg-danger':
                        cell==='Pending'?'bg-warning':
                        'btn-default'}`}> {cell}</span>},

            {dataField: 'code', text: 'Action', editable: false, formatter:(cell:string, row:any)=><div>


<button type="button" name="edit" className="btn btn-warning btn-sm" onClick={()=>handleEditPlan(row)} >  <i className="fa fa-edit"></i> Edit</button>

&nbsp;
&nbsp;
{/* 
 <button type="button" name="delete" data-mdb-toggle="modal"  data-mdb-target={`#warningdialog-${row.code}`}   className="btn btn-danger btn-sm" >  <i className="fa fa-trash"></i> </button>
                 */}
          <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

            </div>}
              
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
             
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No plan found'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }


    const fetchPlan =()=>{
        setNotice({...notice, isDataFetching: true}) 
        var sql ="Select code, ID, planName, duration, minPlan, amount, maxPlan, status, roiRate from tbl_investment_plan";
         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        axios.post(url, fd, config).then(result=>{ 
            setPlan(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
        
    }


    useEffect(()=>{
        fetchPlan() 
         },[]);

    return (<Template title="Investment Plan">

        <div className="container pt-4">
        {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}



        </div>

                  
 {isEditable?<div>

        <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Update Plan</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <form id="action" method="post" action="#!" onSubmit={submit}>

            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Plan Name</span>
                    </div>
                    <input type="text" value={investment.planName}  onChange={handleChange} className="form-control pl-2" id="planName" placeholder=' Name..' name="planName"  required  />
                </div>
            </div>

            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Duration</span>
                    </div>
                    <input type="number" value={investment.duration}  onChange={handleChange} className="form-control pl-2" id="duration" placeholder=' e.g 12 for 12 days..' name="duration" pattern="\d*" required  />
                </div>
            </div>

                    <div className="form-group bmd-form-group is-filled">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Min Amount</span>
                            </div>
                            <input type="number" value={investment.minPlan}  onChange={handleChange} className="form-control pl-2" id="minPlan" placeholder=' Minimum Amount..' name="minPlan" pattern="\d*" required  />
                        </div>
                    </div>


                    <div className="form-group bmd-form-group is-filled">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Max Amount</span>
                            </div>
                            <input type="number" value={investment.maxPlan}  onChange={handleChange} className="form-control pl-2" id="maxPlan" placeholder=' Maximum Amount..' name="maxPlan" pattern="\d*" required  />
                        </div>
                    </div>


                    <div className="form-group bmd-form-group is-filled">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">ROI Rate</span>
                            </div>
                            <input type="number" value={investment.roiRate}  onChange={handleChange} className="form-control pl-2" id="roiRate" placeholder=' Return rate..' name="roiRate" pattern="\d*" required  />
                        </div>
                    </div>


                <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                <div className="input-group-prepend">
                <span className="input-group-text">Status</span>
                </div>
                    <select id="status" name="status" className="form-control pl-2" required value={investment.status} onChange={handleChangeSelect}>
                            <option value="Active"> Active  </option>
                    <option value="Pending"> Pending   </option>
                    <option value="Canceled"> Canceled   </option>
                    </select>
                </div>
                </div>
                               
 {notice.isLoading?
          
          <button disabled className="btn btn-primary" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>: <div>
                  <span className="text-warning">Use 0 to reperesent unlimited Min. and max. deposit amount</span>
                         <button type="submit" className="btn btn-success ml-2">Update Plan</button>&nbsp;

                         <button type="button" onClick={()=>setIsEditable(false)} className="btn btn-danger mr-2">Cancel</button>
                                </div>}

                            </form>
                        </div>
                    </div>
                </div>
            </div>

</div>:''}
            <br />



            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Investment Plans</h2>
                </div>
            </div>

        <div id="plans">
    <div className="row">

        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">

                    <div className="table-responsive">
                    <BasicTable data={plan} columns={tableHeader}  />
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</div>
</Template>
      
    )
}

export default Plans
