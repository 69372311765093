import React, {useState} from 'react'
import { ImagesUrl } from '../components/include'
import Navbar from './navbar'
import Footer from './footer'

const  Forget = ()=> {
    const [errors, setErrors] = useState({
        errorMessage:''
    });
    return (
        <> 
        <header>
           
            <div id="intro" className="bg-image shadow-2-strong">
              <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <div className="container">
                   
    
    
                {errors.errorMessage?
           <div className="container">
            <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
              <strong>Warning!</strong> {errors.errorMessage}
              <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>:''}
    
    
                  <div className="row justify-content-center ">
                    <div className="col-xl-5 col-md-8">
                        
                    <form className="form" method="POST" action="#!"  >
                    <div className="card card-login card-hidden mb-3" style={{ background: 'linear-gradient(#1225f1, #0a1370)'}} >
                        <div className="card-header card-header-warning text-center">
                            <h4 className="card-title"><strong>Forgot Password</strong></h4>
                        </div>
                        <div className="card-body">
                                                        <div className="bmd-form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="material-icons">email</i>
                                      </span>
                                    </div>
                                    <input type="email" name="email" className="form-control pl-2 white"
                                           placeholder="Email..." value="" required />
                                </div>
                                                            </div>
                        </div>
                        <div className="card-footer justify-content-center">
                            <button type="submit" className="btn btn-warning btn-link">Send Password Reset Link</button>
                        </div>
                    </div>
                </form>
                        
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer />

      
      
      </>
        
    )
}

export default Forget
