import React from 'react'
import {ImagesUrl} from './include'

const  Sidebar = (props:{title?:string})=> {
    return (
      
  <nav
  id="sidebarMenu"
  className="collapse d-lg-block sidebar collapse bg-white"
  >
<div className="position-sticky">
 <div className="list-group list-group-flush mx-3 mt-4">
   <a href="/dashboard"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Dashboard'?'active':''}`}
      aria-current="true"
      >
     <i className="fas fa-tachometer-alt fa-fw me-3"></i ><span>Dashboard</span>
   </a>
   <a href="/plans"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Investment Plan'?'active':''}`}

      > <i className="fas fa-chart-bar  fa-fw me-3"></i ><span>Investment Plans</span>
   </a>
   <a
      href="/clients"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Clients'?'active':''}`}

      ><i className="fas  fa-users fa-fw me-3"></i><span>Clients</span></a >
   <a
      href="/investments"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Client Investments'?'active':''}`}
      ><i className="fas fa-suitcase fa-fw me-3"></i><span>Client Investments</span></a>


   <a
      href="/earnings"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Earnings'?'active':''}`}
      >
     <i className="fas fa-chart-pie fa-fw me-3"></i><span>Earnings</span>
   </a>



   <a
      href="/topup"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Top up'?'active':''}`}
      ><i className="fas fa-mug-hot fa-fw me-3"></i><span>Credit Transaction</span></a>



   <a
      href="/online_payment"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Crypto'?'active':''}`}
      ><i className="fas fa-globe fa-fw me-3"></i><span>Online Payment</span></a>




<a
      href="/payout"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Payout Request'?'active':''}`}
      ><i className="fas fa-money-bill fa-fw me-3"></i><span>Payout Request</span></a>

   <a
      href="/referrals"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Referrals'?'active':''}`}
      ><i className="fas fa-desktop fa-fw me-3"></i><span>Referrals</span></a>

  
<a
      href="/messages"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Message'?'active':''}`}
      ><i className="fas fa-envelope fa-fw me-3"></i><span>Send Message</span></a>

   <a
       href={'/?refferer='+window.location.href}
      className="list-group-item list-group-item-action py-2 ripple "
      ><i className="material-icons">login</i><span>Logout</span></a>



 </div>
</div>
</nav>

       
    )
}

export default Sidebar
