import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'
import Template from '../components/template'


import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";
const  MessagesBulk = ()=> {
    

    const { quill, quillRef } = useQuill();
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [errors, setErrors] = useState({
        successMessage:'',
        errorMessage:'',
        confirmPassword:''
    });

    const [message, setMessage] = useState({
        clients:'',
        content:'',
        subject:'',
    })
    const handleChangeSubject = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
        setMessage({...message, [name] : value });
    }

    const handleChangeDescription =(data:string)=>{
		setMessage({...message, content : data });
	}

    const [users, setUsers] = useState([] as any[])


    const fetchContent =()=>{
       
        var sql ="Select firstname, lastname, email from tbl_users";
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        axios.post(url, fd, config).then(result=>{ 
            setUsers(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }


      const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        setNotice({...notice, isLoading: true})     
        const fd = new FormData();
    
        fd.append('address', JSON.stringify(users, null, 2));
        fd.append('subject', message.subject);
        fd.append('message', String(quill?.root.innerHTML));
        fd.append('jwt', Token);
        
       let url = ServerUrl+'/save_controller.php?tablename=tbl_sendbulk_message';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
            Alerts('Success!', 'success', response.data.message)
               } else{
                 
                window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                setNotice({...notice,  isLoading: false })
                setMessage({
                    clients:'',
                    content:'',
                    subject:'',
                })
              })  

      }


      useEffect(()=>{
        fetchContent() 
         },[]);

    return (<Template title={"Message"}>
       <div className="container pt-4">
          
            
       {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


       <div className="row mt-2">
                <div className="col-md-10 offset-lg-1">
                    <div className="card">
                    <div className="card-header">
                            <h5 className="header-title text-center">Send Bulk Email To Customers</h5>
                        </div>
                        <div className="card-body">
                            <form id="action" method="post" action="#!" onSubmit={submit}>


                            <div className="form-group mb-3">
                                    <label  className="bmd-label-static">Subject</label>

                                    <input type="text" name="subject"  value={message.subject} placeholder="e.g Happy New Month" required  onChange={handleChangeSubject} className='form-control' /> 

                                </div>


                            <div className="form-group">
                            <label  className="bmd-label-static">Email Message</label>

                        <div style={{ width: '100%', height: 200 }}>
                        <div ref={quillRef} />
                        </div>

                        <div className='' style={{ height:'100px' }} />

						{/* <QuillText value={message.content} onChange={handleChangeDescription} />
 */}
                                </div>
                          {notice.isLoading?
          
          <button disabled className="btn btn-primary mt-3" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>: 
                         <button type="submit" className="btn btn-success mt-3">Send Messgae</button>} 

                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>
</Template>
      
    )
}

export default MessagesBulk
