import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Login from './general/login'
import ProtectedView  from './protected_router'
import Forget from './general/forget'
import Register from './general/register'

function App() {
  return (<>
<ReactNotifications />
       <Router  >
        <Switch>
        <Route exact path="/" component={Login} />

        <Route exact path="/forgot_password" component={Forget} />
        <ProtectedView />

        
   {/*      
        
    <Route exact path="/error403" component={error403} />
    <Route exact path="/error500" component={error500} />
    <Route exact  component={Error404} /> */}
        </Switch>
          </Router>
     </>
        );
      }

export default App;
