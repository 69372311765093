import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts, WarningModal } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl, Currency,  Token, Code} from '../components/include'
import Template from '../components/template'
import { useSelector } from 'react-redux'
import { FormatNumber } from '../components/globalFunction';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import {Type} from 'react-bootstrap-table2-editor';
const  Payout = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [deposit, setDeposit] = useState([] as any[])

    const [withdrawal, setWithdrawal] = useState({
        method:'Bitcoin',
        amount:'',
        information:'',
        account:'',
        twoFA:'',
        userCode:Code,
        code:'p'+Math.random().toString(36).slice(2,9)
    })

    const user  = useSelector((state:any) => state.rootReducer.user);
    var balance = Number(user.balance)
    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:''
    });
    
    const handleMethod = (methodName:string)=>{
        setWithdrawal({...withdrawal, method : methodName });
        document.getElementById('modalBtn')?.click()
    }
    
    

    const handleDelete = (code:string)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close?.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller.php?tablename=tbl_payout_request'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
             fetchContent()
               setNotice({...notice, isLoading: false
               })
           }) 
    }


    const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

        if (withdrawal.account === 'Main Wallet'){

            if(Number(withdrawal.amount) > Number(user.balance)){
                errormessage.push('error');
            Alerts('Error!', 'danger', 'The amount to withdraw is more than your balance');
              
            }
        }

          
        if (withdrawal.account === 'Referral Earning'){
            if (Number(withdrawal.amount) > Number(user.referal)){
                Alerts('Error!', 'danger', 'The amount to withdraw is more than your balance')
                  errormessage.push('error');
                }

            }




        if (Number(withdrawal.amount) > 100000){
        Alerts('Oops!', 'danger', ' Your request amount is higher than the maximum for the selected payout method!.')
              errormessage.push('error');
            }


            if (Number(withdrawal.amount) <= 0){
                Alerts('Oops!', 'danger', ' Your request amount is less than the minimum for the selected payout method!.')
                      errormessage.push('error');
                    }

    
        
    if (user.isTwoFA==='Active' && withdrawal.twoFA !== user.twoFA){
        document.getElementById('closeModal')?.click()
        setErrors({...errors, errorMessage: 'The 2FA entered is  does not match'})
              errormessage.push('error');
        }

       
     if (errormessage.length <=0) {
       setNotice({...notice, isLoading: true})     
        const fd = new FormData();
        Object.entries(withdrawal).forEach(([key, value]) => {
            fd.append(key, value);
        }); 
        fd.append('jwt', Token);

        let url = ServerUrl+'/save_controller.php?tablename=tbl_payout';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
         setErrors({...errors, successMessage: 'Your payout request was successfully received!.'})
               
                  } else{
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                document.getElementById('closeModal')?.click()
                setNotice({...notice,  isLoading: false })
                setWithdrawal({
                    method:'Bitcoin',
                    amount:'',
                    information:'',
                    account:'',
                    twoFA:'',
                    userCode:Code,
                    code:'p'+Math.random().toString(36).slice(2,9)
                })

              })   
        } 
    }
    const handleCompleted =(row:any)=>{
        setNotice({...notice,  isLoading: true}) 
           const fd = new FormData();
           fd.append('jwt', Token); 
        fd.append('amount', row.amount)
         fd.append('method', row.method)

         fd.append('firstname', row.firstname+' '+row.lastname)
         fd.append('email', row.email)
         fd.append('information', row.information)
         fd.append('code', row.code)
 
         let url = ServerUrl+'/save_controller.php?tablename=tbl_complete_payout'
        axios.post(url, fd, config)
        .then(response =>{
         if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
          fetchContent()
            setNotice({...notice, isLoading: false
            })
        })
    }

var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'ID', editable: false, formatter: (cell:Number)=>i++},
        {dataField: 'firstname', text: 'Username', editable: false, formatter:(cell:number, row:any)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {row.firstname + " "+row.lastname}</span> },
        {dataField: 'fromAccount', text: 'From', editable: false},
        {dataField: 'method', text: 'Method', editable: false},
        {dataField: 'amount', text: 'Amount', editable: false, formatter: (cell:Number)=>FormatNumber(cell)+" "+Currency.title},

        {dataField: 'information', text: 'Information', editable: false, formatter:(cell:number)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> {cell}</span>},

        {dataField: 'createdAt', text: 'Created at', editable: false},
        {dataField: 'status', text: 'Status',  formatter:(cell:string, row:any)=>cell==="Pending"?<button onClick={()=>handleCompleted(row)} className='btn btn-warning' type='button'>{cell}</button>:<span className='badge bg-success'>{cell}</span>},
                       

           /*  {dataField: 'code', text: 'Action', editable: false, formatter:(cell:string, row:any)=><div>

                 <button type="button" name="delete" data-mdb-toggle="modal"  data-mdb-target={`#warningdialog-${row.code}`}   className="btn btn-danger btn-sm" >  <i className="fa fa-trash"></i> Delete</button>
                                
                          <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 
                
                            </div>} */
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
        const handleUpdate=(column:any, newValue:any, ID:any)=>{       
            const fd = new FormData(); 
            fd.append("jwt", Token);
                fd.append('columnField', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
               let url = ServerUrl+'/updateById_controller.php?tablename=tbl_payout_request';
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>console.log(error)) 
              
            } 

            const { SearchBar } = Search;
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                               <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
 </div><br/>
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No payout requests'}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue:any, newValue:any, row:any, column:any) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.ID);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      const fetchContent =()=>{
          setNotice({...notice, isDataFetching: true}) 
          var sql ="Select p.ID, p.code, p.fee, p.fromAccount, p.method, p.amount, p.information,	p.status,	p.userCode, p.createdAt, p.updatedAt, u.firstname, u.email, u.lastname from tbl_users u, tbl_payout_request p  where p.userCode =u.code order by ID DESC ";
  
          const fd = new FormData();
          fd.append("sql", sql);
          fd.append('jwt', Token);
          let url = ServerUrl+'/fetchBySql_controller.php'
          
          axios.post(url, fd, config).then(result=>{ 
              setDeposit(result.data)
          })
          .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, isDataFetching: false}) 
          })
      }

    useEffect(()=>{
        fetchContent() 
         },[]);


    
    return (<Template title="Payout Request">
     
{notice.isDataFetching? <div className="loader"> <img src={ImagesUrl+"/loading.gif"} /> 
          </div>: <div className="container">

    
{errors.successMessage?
   
   <div className="alert alert-dismissible alert-success">
                       <button type="button" className="close" data-dismiss="alert">×</button>
                       <strong>Success!</strong> {errors.successMessage}
                   </div>:''}
{errors.errorMessage?
   
   <div className="alert alert-dismissible alert-warning">
                       <button type="button" className="close" data-dismiss="alert">×</button>
                       <strong>Error!</strong> {errors.errorMessage}
                   </div>:''}


<div className="row">
    <div className="col-lg-12">
        <a href="#payouts" className="btn btn-lg btn-block btn-info"> Payout Requests<div className="ripple-container"></div></a>
    </div>

</div>


<div id="payouts">
    <div className="row">

        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">

                    <div className="table-responsive">

{notice.isLoading? <div className="loader"> <img src={ImagesUrl+"/loading.gif"} /> 
          </div>:
                    <BasicTable data={deposit} columns={tableHeader}  />}
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</div>

</div>}
</Template>
      
    )
}

export default Payout
