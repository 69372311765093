import React, {useState, useEffect, FormEvent, ChangeEvent, useRef} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency, SiteLink} from '../components/include'
import Template from '../components/template'

import { useSelector } from 'react-redux'
import {FormatNumber} from '../components/globalFunction'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

//import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const  Dashboard = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false,
        isEdit:false
	});

  const [bank, setBank] = useState({
    code:'',
    account_name:'',
    bank_name:'',
    account_number:'',
    iban:'',
    switch_code:'',
    address:''
  })
  const [editBank, setEditBank] = useState([] as any[]) 
    const [stat, setStat] = useState({
      '0':false,
        balance:0,
        referal:0,
        withdraw:0,
        deposit:0,
        investment:0,
        user:0,
        invest:0
    })
    const [referal, setReferal] = useState([] as any[])
    const user  = useSelector((state:any) => state.rootReducer.user);
    const copyToClipboard =()=>{
         
        const el = document.createElement('textarea');
        el.value = SiteLink+"/register?ref="+Code;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        Alerts('Info!', 'info', 'Referral link copied to clipboard')
    }


    const [errors, setErrors] = useState({
      errorMessage:''
  });

    const fetchStat =()=>{

      var sql ="Select  (select sum(amount) from tbl_deposit where status ='Completed' ) as deposit, (select sum(amount) from tbl_payout_request where status ='Completed' and fromAccount ='Main Wallet' ) as withdraw, (select sum(amountEarn) from tbl_earnings where earnType <> 'Capital' ) as investmentReturn, (select count(*) from tbl_user_investment where status='active' ) as investment, (select sum(referal) from tbl_users  ) as referal, (select sum(investment) from tbl_user_investment where status='active' ) as invest, (select count(ID) from tbl_users where status='Active'  ) as user    ";

       

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setStat({
...stat,
                referal:result.data[0].referal,
                balance:(Number(result.data[0].deposit)+Number(result.data[0].investmentReturn))-(Number(result.data[0].withdraw)+Number(result.data[0].invest)),
                investment:result.data[0].investment,
                deposit:Number(result.data[0].deposit)+Number(result.data[0].investmentReturn),
                invest:result.data[0].invest,
                withdraw:result.data[0].withdraw,
                user:result.data[0].user
            })
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }


    const [invest,  setInvest] = useState([] as any[])



    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 

        var sql ="Select e.ID, e.amountEarn, e.balanceBefore, e.balanceAfter,	e.creditAt, concat(c.firstname, ' ', c.lastname) as username,  p.code, p.planName, u.investment from tbl_earnings e, tbl_user_investment u, tbl_users c, tbl_investment_plan p  where e.planCode = p.code and c.code =e.userCode and   u.code = e.contractCode order by e.ID DESC "

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setInvest(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }

   
var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'ID', editable: false, formatter:(cell:number)=>i++},
        {dataField: 'username', text: 'Client', editable: false},


        {dataField: 'planName', text: 'Investment Plan', editable: false},
        {dataField: 'amountEarn', text: 'Amount earned', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(cell)+ ' '+Currency.title}</span>},

        {dataField: 'balanceBefore', text: 'Balance Before', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(cell) + " "+Currency.title}</span>},

        {dataField: 'balanceAfter', text: 'Balance After', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> { FormatNumber(cell) + " "+Currency.title}</span>},

        {dataField: 'creditAt', text: 'Release At', editable: false},
     ];



    
  
     const BasicTable=(props:{data:any, columns:any})=>{
             
            const { SearchBar } = Search;
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };


              const handleUpdate=(column:any, newValue:string, ID:string)=>{       
                const fd = new FormData(); 
                fd.append("jwt", Token);
                    fd.append('columnField', newValue);
                    fd.append('column', column.dataField);
                    fd.append('ID', ID);
                    
                  
                   let url = ServerUrl+'/updateById_controller/tbl_students';
                  axios.post(url, fd, config)
                  //.then(result => console.log(result.data))
                  .then()
                  .catch((error)=>console.log(error)) 
                  
                } 

      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                                    <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
 </div><br/>
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No Investment Return Found'}
                                     pagination={paginationFactory(options) }

                                  

                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    


      const fetchBank =()=>{
        setNotice({...notice, isDataFetching: true}) 


        var sql ="Select ID, code, bank_name, account_number, account_name, iban, switch_code, address from tbl_bank_account where code ='eds' limit 1";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 

          if(result.data.length!==0){

            setBank(result.data[0])
          }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }

      const fetchRefer =()=>{
        setNotice({...notice, isDataFetching: true}) 
        var sql ="Select ID, code, username, createAt, amount from tbl_refferal_list where referBy ='"+Code+"' ";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setReferal(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }


    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	

      setBank({...bank, [name]:[value]})
      }

      const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

      

     if (errormessage.length<=0) {
       
        setNotice({...notice, isLoading: true})     
        const fd = new FormData();
        Object.entries(bank).forEach(([key, value]) => {
            fd.append(key, value);
        }); 
        fd.append('jwt', Token);

        let url = ServerUrl+'/update_controller.php?tablename=tbl_update_bank';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'info'){
            Alerts('Success!', 'info', 'You have successfully updated bank details!')
          
                  } else{
                
            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                setNotice({...notice,  isLoading: false })
              }) 

        } 
    }



  useEffect(()=>{
    fetchRefer()
      fetchContent() 
      fetchStat()
      fetchBank()
       },[]);

    return (<Template title="Dashboard">
       
<div className="container pt-4">


<section>
      <div className="row">
        <div className="col-xl-4 col-sm-6 col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div className="align-self-center">
                  <i className="fas fa-wallet text-danger fa-3x"></i>
                </div>
                <div className="text-end">
                  <h3>{FormatNumber(Number(stat.balance))+" "+Currency.title}</h3>
                  <p className="mb-0">User Balance Remain</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6 col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div className="align-self-center">
                  <i className="fa fa-suitcase text-primary fa-3x"></i>
                </div>
                <div className="text-end">
                  <h3>{FormatNumber(Number(stat.referal))+" "+Currency.title}</h3>
                  <p className="mb-0">User Referral Remain</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6 col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div className="align-self-center">
                  <i className="fas fa-chart-line text-dark fa-3x"></i>
                </div>
                <div className="text-end">
                <h3>{stat.investment} ({FormatNumber(Number(stat.invest))+" "+Currency.title})</h3>
                  <p className="mb-0">Active Investment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-xl-4 col-sm-6 col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div>
                  <h3 className="text-success">{FormatNumber(Number(stat.deposit))+" "+Currency.title}</h3>
                  <p className="mb-0">Total Deposit & Returns</p>
                </div>
                <div className="align-self-center">
                  <i className="fas fa-mug-hot text-success fa-3x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6 col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div>
                  <h3 className="text-info">{FormatNumber(Number(stat.withdraw))+" "+Currency.title}</h3>
                  <p className="mb-0">Total Payout</p>
                </div>
                <div className="align-self-center">
                  <i className="far fa-credit-card text-info fa-3x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6 col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div>
                  <h3 className="text-warning">{stat.user}  </h3>
                  <p className="mb-0">Total Active User</p>
                </div>
                <div className="align-self-center">
                  <i className="fas fa-users text-warning fa-3x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</section>






<hr />
            <br />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="header-title text-center">Investments Return</h3>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                            <BasicTable data={invest} columns={tableHeader}  />

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <hr />
            <br />


            <div>

        <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Update Bank Account</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <form id="action" method="post" action="#!" onSubmit={submit}>

            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Bank Name</span>
                    </div>
                    <input type="text" value={bank.bank_name}  onChange={handleChange} className="form-control pl-2" id="bank_name" placeholder='Bank Name..' name="bank_name"  required  />
                </div>
            </div>

            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Account Number</span>
                    </div>
                    <input type="number" value={bank.account_number}  onChange={handleChange} className="form-control pl-2" id="account_number" placeholder=' e.g 12 for 12 days..' name="account_number" pattern="\d*" required  />
                </div>
            </div>

            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Account Name</span>
                    </div>
                    <input type="text" value={bank.account_name}  onChange={handleChange} className="form-control pl-2" id="account_name" placeholder='Account Name..' name="account_name"  required  />
                </div>
            </div>




            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">IBAN</span>
                    </div>
                    <input type="text" value={bank.iban}  onChange={handleChange} className="form-control pl-2" id="iban" placeholder='Iban..' name="iban"  required  />
                </div>
            </div>



            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Switch Code</span>
                    </div>
                    <input type="text" value={bank.switch_code}  onChange={handleChange} className="form-control pl-2" id="switch_code" placeholder='Switch Code..' name="switch_code"  required  />
                </div>
            </div>


            <div className="form-group bmd-form-group is-filled">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Address</span>
                    </div>
                    <input type="text" value={bank.address}  onChange={handleChange} className="form-control pl-2" id="address" placeholder='Address..' name="address"  required  />
                </div>
            </div>


             {notice.isEdit? <div> 
              

 {notice.isLoading?
          
          <button disabled className="btn btn-primary" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>: <div>
                         <button type="submit" className="btn btn-success ml-2">Update Account</button>&nbsp;

                         <button type="button" onClick={()=>setNotice({...notice, isEdit:!notice.isEdit})} className="btn btn-danger mr-2">Cancel</button>
                                </div>}
         
         </div>:
         
         
         <button  className="btn btn-warning btn-link btn-sm" onClick={()=>setNotice({...notice, isEdit:!notice.isEdit})} type="button"> <i className='fa fa-edit'></i> Edit</button>}

                            </form>
                        </div>
                    </div>
                </div>
            </div>

</div>
<hr />
            <br />

</div>
       
       
       
       

</Template>
      
    )
}

export default Dashboard
